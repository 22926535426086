import * as yup from 'yup';

import {
  DOMAIN_NAME_REGEXP,
  ORGANIZATION_NAME_REGEXP,
  PHONE_NUMBER_REGEXP,
} from '../../../constants';

export const schema = yup.object().shape({
  organizationName: yup
    .string()
    .required('No organization name provided')
    .max(20, 'With a limitation of 2-20 characters')
    .matches(
      ORGANIZATION_NAME_REGEXP,
      'Can be letters and numbers, the first character must be a letter'
    ),
  url: yup.string().required('No url provided').matches(DOMAIN_NAME_REGEXP, 'Wrong url format'),
  //TODO - fix this
  //.url('Invalid URL'),
  phoneNumber: yup
    .string()
    .required('No phone number provided')
    .matches(PHONE_NUMBER_REGEXP, 'Wrong format'),
});
