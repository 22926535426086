import React from 'react';

import { MainContainer } from '../../../components/MainContainer';

import { CreateCardHookForm } from './CreateCardHookForm';

const CreateCard = () => {
  return (
    <MainContainer>
      <CreateCardHookForm />
    </MainContainer>
  );
};

export default CreateCard;
