import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { handleFetches } from '../API/fetch';
import { BASE_URL, DEFAULT_LINK, TEMPLATES_LINK, UPLOAD_URL } from '../constants';
import { FETCH_COMPANY_TEMPLATES_POSTFIX } from '../constants/endpoints';
import { handleRequestErrors } from '../helpers/handleErrors';

const initialState = {
  templates: [],
  totalTemplates: 0,
  loading: false,
  isSucces: false,
  isError: false,
  response: {},
};

export const getCompanyTemplates = createAsyncThunk(
  'templates/get-templates',
  (body, { rejectWithValue }) => {
    return handleFetches(
      `${BASE_URL}${FETCH_COMPANY_TEMPLATES_POSTFIX}`,
      { method: 'GET', body: body },
      true,
      false,
      { handlerError: handleRequestErrors, showAlert: false },
      rejectWithValue
    );
  }
);

export const deleteTemplate = createAsyncThunk(
  'templates/delete-template',
  ({ id }, { rejectWithValue }) => {
    return handleFetches(
      `${BASE_URL}${TEMPLATES_LINK}${DEFAULT_LINK}${id}`,
      {
        method: 'DELETE',
        body: { id: id },
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
      true,
      false,
      { handlerError: null, showAlert: false },
      rejectWithValue
    );
  }
);

export const updateCompanyTemplate = createAsyncThunk(
  'templates/update-template',
  ({ formData, id }, { rejectWithValue }) => {
    console.log(id);
    return handleFetches(
      `${BASE_URL}${TEMPLATES_LINK}${DEFAULT_LINK}${id}`,
      {
        method: 'PUT',
        body: formData,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
      true,
      false,
      { handlerError: handleRequestErrors },
      rejectWithValue
    );
  }
);

export const uploadCompanyTemplates = createAsyncThunk(
  'templates/upload-templates',
  (body, { rejectWithValue }) => {
    return handleFetches(
      `${UPLOAD_URL}${TEMPLATES_LINK}`,
      {
        method: 'POST',
        body: body,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
      true,
      false,
      { handlerError: handleRequestErrors },
      rejectWithValue
    );
  }
);

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: {
    [deleteTemplate.fulfilled]: (state) => {
      state.totalTemplates -= 1;
    },
    [getCompanyTemplates.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSucces = true;
      state.templates = action.payload;
      state.totalTemplates = action.payload.total;
    },
    [getCompanyTemplates.pending]: (state) => {
      state.loading = true;
    },
    [getCompanyTemplates.rejected]: (state) => {
      state.loading = false;
      state.isError = true;
    },
    [uploadCompanyTemplates.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSucces = true;
      state.response = action.payload;
      state.totalTemplates += 1;
    },
    [uploadCompanyTemplates.pending]: (state) => {
      state.loading = true;
    },
    [uploadCompanyTemplates.rejected]: (state) => {
      state.loading = false;
      state.isError = true;
    },
  },
});

export default templatesSlice.reducer;
