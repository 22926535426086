import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { MainContainer } from '../../../components/MainContainer';
import {
  CREATE_TEMPLATE_LINK,
  CREATE_TEMPLATE_SUCCESS_MESSAGE,
  CREATE_TEMPLATE_ERROR_MESSAGE,
  MY_TEMPLATES_LINK,
} from '../../../constants';
import { isFulfilled, isRejected } from '../../../helpers/handleRequest';
import { showErrorMessage, showSuccessMessage } from '../../../helpers/messages';
import { uploadCompanyTemplates } from '../../../redux/templatesSlice';

import { useData } from './DataContext';

export const Result = () => {
  const { data } = useData();
  const dispatch = useDispatch();
  const history = useHistory();
  const entries = Object.entries(data).filter((entry) => entry);

  const createTemplate = async (values) => {
    let formdata = new FormData();

    formdata.append('name', values.name);
    formdata.append('foregroundColor', values.foregroundColor);
    formdata.append('backgroundColor', values.backgroundColor);
    formdata.append('Logo', values.Logo);
    formdata.append('Icon', values.Icon);
    formdata.append('Background', values.Background);
    formdata.append('Strip', values.Strip);

    const response = await dispatch(uploadCompanyTemplates(formdata));
    const requestStatus = response.meta.requestStatus;

    if (isFulfilled(requestStatus)) {
      showSuccessMessage(CREATE_TEMPLATE_SUCCESS_MESSAGE);
      history.push(MY_TEMPLATES_LINK);
      return;
    }
    if (isRejected(requestStatus)) {
      showErrorMessage(CREATE_TEMPLATE_ERROR_MESSAGE);
      return;
    }
  };
  return (
    <MainContainer>
      <Typography component="h2" variant="h5">
        📋 Result(Form values)
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell align="right">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{entries[0][0]}</TableCell>
              <TableCell align="right">{entries[0][1]}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{entries[1][0]}</TableCell>
              <TableCell align="right">{entries[1][1]}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{entries[2][0]}</TableCell>
              <TableCell align="right">{entries[2][1]}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{entries[3][0]}</TableCell>
              <TableCell align="right">{entries[3][1].name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{entries[4][0]}</TableCell>
              <TableCell align="right">{entries[4][1].name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{entries[5][0]}</TableCell>
              <TableCell align="right">{entries[5][1].name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{entries[6][0]}</TableCell>
              <TableCell align="right">{entries[6][1].name}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Link to={CREATE_TEMPLATE_LINK}>Start over</Link>
      <Box marginTop={5}>
        <Button variant="contained" color="primary" onClick={() => createTemplate(data)}>
          Создать
        </Button>
      </Box>
    </MainContainer>
  );
};
