import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';

const AddFlowingButton = React.memo(function AddFlowingButton({ onClick }) {
  return (
    <Fab color="primary" aria-label="add" onClick={onClick}>
      <AddIcon />
    </Fab>
  );
});

AddFlowingButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddFlowingButton;
