import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import cls from './ButtonStyles.module.css';

const SubmitFormButton = React.memo(function SubmitFormButton({ text, disabled }) {
  return (
    <Box className={cls.BoxForButton}>
      <Button variant="contained" color="primary" type="submit" disabled={disabled}>
        {text}
      </Button>
    </Box>
  );
});

SubmitFormButton.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default SubmitFormButton;
