import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { ColorInput } from '../../../components/ColorInput';
import { FormWrapper } from '../../../components/FormWrapper';
import { MainContainer } from '../../../components/MainContainer';
import SubmitFormButton from '../../../components/UI/Buttons/SubmitFormButton';
import { TEMPLATE_BACKGROUND_COLOR, TEMPLATE_FOREGROUND_COLOR } from '../../../constants/constants';
import { TEMPLATE_STEP_THREE } from '../../../constants/router';

import { useData } from './DataContext';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const Step2 = () => {
  const history = useHistory();
  const { data, setValues } = useData();
  const styles = useStyles();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      foregroundColor: TEMPLATE_FOREGROUND_COLOR || data.foregroundColor,
      backgroundColor: TEMPLATE_BACKGROUND_COLOR || data.backgroundColor,
    },
  });

  const onSubmit = (data) => {
    setValues(data);
    history.push(TEMPLATE_STEP_THREE);
  };

  return (
    <MainContainer>
      <Typography component="h2" variant="h5">
        🦄 Step 2 of template creation
      </Typography>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <ColorInput control={control} name="foregroundColor" errors={errors} styles={styles} />
        <ColorInput control={control} name="backgroundColor" errors={errors} styles={styles} />
        <SubmitFormButton text="Next step" />
      </FormWrapper>
    </MainContainer>
  );
};
