import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Container, Button } from '@material-ui/core';
import { Alert } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { FormWrapper } from '../../../components/FormWrapper';
import { Input } from '../../../components/Input';
import SubmitFormButton from '../../../components/UI/Buttons/SubmitFormButton';
import { QR_LINK, PASS2U_LINK } from '../../../constants';

import cls from './CardCreated.module.css';

const schema = yup.object().shape({
  mail: yup.string().email('Invalid email address').required('No e-mail provided'),
});

export const CardCreated = () => {
  const { id } = useParams();

  const {
    register,
    //handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { mail: '' },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  return (
    <Container className={cls.CardCreatedContainer}>
      <h1>Карта создана</h1>
      <Alert severity="warning">
        <span>
          Для работы с электронными картами на Android сначала скачайте приложение{' '}
          <a href={PASS2U_LINK} target="_blank" rel="noreferrer">
            Pass2U
          </a>
        </span>
      </Alert>
      <hr />
      <Box className={cls.CardCreatedContent}>
        <FormWrapper onSubmit={() => alert(`Карта айди ${id} отправлена на почту!`)}>
          <Input
            {...register('mail')}
            id="mail"
            type="text"
            label="email"
            error={!!errors.name}
            helperText={errors?.name?.mail}
          />
          <div className={cls.CardCreatedButtonsContainer}>
            <SubmitFormButton text="Отправить на почту" />
            <Button variant="outlined" onClick={() => alert(`Карта айди ${id} скачана!`)}>
              Скачать
            </Button>
          </div>
        </FormWrapper>
        <img className={cls.CardCreatedQR} alt="QR-код карты" src={QR_LINK} />
      </Box>
    </Container>
  );
};
