import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { FormWrapper } from '../../../components/FormWrapper';
import { Input } from '../../../components/Input';
import { MainContainer } from '../../../components/MainContainer';
import SubmitFormButton from '../../../components/UI/Buttons/SubmitFormButton';
import { PROFILE_LINK } from '../../../constants';
import { updateCompanyData } from '../../../redux/usersSlice';

import { schema } from './updateCompanyShema';

export const UpdateCompanyHookForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      organizationName: '',
      url: '',
      phoneNumber: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmitRedirectToProfile = () => {
    history.push(PROFILE_LINK);
  };

  const updateCompany = async (values) => {
    const data = await dispatch(updateCompanyData(values));
    if (data.meta.requestStatus === 'fulfilled') {
      onSubmitRedirectToProfile();
    }
  };

  const onSubmit = (data) => {
    updateCompany(data);
  };

  return (
    <MainContainer>
      <Typography component="h2" variant="h5">
        Изменить данные о компании
      </Typography>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('organizationName')}
          id="organizationName"
          type="text"
          label="Название компании"
          error={!!errors.organizationName}
          helperText={errors?.organizationName?.message}
        />
        <Input
          {...register('url')}
          id="url"
          type="text"
          label="Сайт компании"
          error={!!errors.url}
          helperText={errors?.url?.message}
        />
        <Input
          {...register('phoneNumber')}
          id="phoneNumber"
          type="tel"
          label="Телефон"
          error={!!errors.phoneNumber}
          helperText={errors?.phoneNumber?.message}
        />
        <SubmitFormButton text="Сменить" />
      </FormWrapper>
    </MainContainer>
  );
};
