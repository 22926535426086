import * as yup from 'yup';

import { PASSWORD_REGEXP } from '../../constants';

export const schema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('No password provided')
    .matches(PASSWORD_REGEXP, 'Password can only contain Latin letters.'),
  newPassword: yup
    .string()
    .required('No password provided')
    .matches(PASSWORD_REGEXP, 'Password can only contain Latin letters.'),
  newPasswordCheck: yup
    .string()
    .required('No password provided')
    .matches(
      PASSWORD_REGEXP,
      'Строчные и прописные латинские буквы, цифры, спецсимволы. Минимум 8 символов'
    )
    .oneOf([yup.ref('newPassword')], 'Password missmatch'),
});
