import { Fab } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import React from 'react';

const EditFlowingButton = React.memo(function EditFlowingButton({ onClick }) {
  return (
    <Fab color="primary" aria-label="add" onClick={onClick}>
      <EditIcon />
    </Fab>
  );
});

EditFlowingButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EditFlowingButton;
