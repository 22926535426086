import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuOpened: false,
};

const menuSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showMenu(state) {
      state.menuOpened = true;
    },
    hideMenu(state) {
      state.menuOpened = false;
    },
  },
});

export const { showMenu, hideMenu } = menuSlice.actions;
export default menuSlice.reducer;
