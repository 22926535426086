import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as AiIcons from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { FormWrapper } from '../../components/FormWrapper';
import { Input } from '../../components/Input';
import { MainContainer } from '../../components/MainContainer';
import SubmitFormButton from '../../components/UI/Buttons/SubmitFormButton';
import { REGISTER_LINK, WRONG_EMAIL_PASS, LOGIN_SUCCESS_MESSAGE } from '../../constants';
import { isFulfilled, isRejected } from '../../helpers/handleRequest';
import { showErrorMessage, showSuccessMessage } from '../../helpers/messages';
import { loginClient } from '../../redux/authSlice';

import { schema } from './loginShema';

export const LoginHookForm = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '', password: '' },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const login = async (values) => {
    const response = await dispatch(loginClient(values));
    const requestStatus = response.meta.requestStatus;

    if (isFulfilled(requestStatus)) {
      showSuccessMessage(LOGIN_SUCCESS_MESSAGE);
      return;
    }
    if (isRejected(requestStatus)) {
      showErrorMessage(WRONG_EMAIL_PASS);
      return;
    }
  };

  const onSubmit = (data) => {
    login(data);
  };

  return (
    <MainContainer>
      <Typography component="h2" variant="h5" align="center">
        <AiIcons.AiOutlineLogin />
        <span>Вход</span>
      </Typography>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('email')}
          id="email"
          type="email"
          label="Email"
          error={!!errors.email}
          helperText={errors?.email?.message}
        />
        <Input
          {...register('password')}
          id="password"
          type="password"
          label="Password"
          error={!!errors.password}
          helperText={errors?.password?.message}
        />
        <SubmitFormButton text="Войти" />
      </FormWrapper>
      <Box>
        <div>
          <span>Еще не зарегистрированы? </span>
          <NavLink to={REGISTER_LINK}>
            <span>Создайте учетную запись!</span>
          </NavLink>
        </div>
      </Box>
    </MainContainer>
  );
};
