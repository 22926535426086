import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  CARD_CREATED_LINK,
  CHANGE_PASSWORD_LINK,
  CREATE_CARD_LINK,
  CREATE_TEMPLATE_LINK,
  DEFAULT_LINK,
  EDIT_TEMPLATE_LINK,
  LOGIN_LINK,
  MY_TEMPLATES_LINK,
  PROFILE_LINK,
  REGISTER_LINK,
  UPDATE_COMPANY_LINK,
} from '../../constants';
import { CardCreated } from '../../pages/Cards/CardCreated/CardCreated';
import CreateCard from '../../pages/Cards/CreateCard/CreateCard';
import { ChangePassword } from '../../pages/ChangePassword';
import { CompanyRegistration } from '../../pages/CompanyContainer/CompanyRegistration';
import { UpdateCompany } from '../../pages/CompanyContainer/UpdateCompany';
import { Login } from '../../pages/Login';
import { Profile } from '../../pages/Profile';
import { CreateTemplate } from '../../pages/Templates/CreateTemplate';
import EditTemplate from '../../pages/Templates/EditTemplate/EditTemplate';
import { MyTemplates } from '../../pages/Templates/MyTemplates';
import { checkAuthStatus } from '../../redux/authSlice';

export const AppRouter = React.memo(function AppRouter() {
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.authStatus);

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  return authStatus ? (
    <Switch>
      <Route path={DEFAULT_LINK} exact component={Profile} />
      <Route path={MY_TEMPLATES_LINK} component={MyTemplates} />
      <Route path={CREATE_TEMPLATE_LINK} component={CreateTemplate} />
      <Route path={PROFILE_LINK} component={Profile} />
      <Route path={CHANGE_PASSWORD_LINK} component={ChangePassword} />
      <Route path={UPDATE_COMPANY_LINK} component={UpdateCompany} />
      <Route path={`${CREATE_CARD_LINK}${DEFAULT_LINK}:id`} component={CreateCard} />
      <Route path={`${CARD_CREATED_LINK}${DEFAULT_LINK}:id`} component={CardCreated} />
      <Route path={`${EDIT_TEMPLATE_LINK}${DEFAULT_LINK}:id`} component={EditTemplate} />
      <Redirect to={DEFAULT_LINK} />
    </Switch>
  ) : (
    <Switch>
      <Route path={REGISTER_LINK} component={CompanyRegistration} />
      <Route path={LOGIN_LINK} component={Login} />
      <Route path={DEFAULT_LINK} exact component={Login} />
      <Redirect to={DEFAULT_LINK} />
    </Switch>
  );
});
