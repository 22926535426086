import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import * as CgIcons from 'react-icons/cg';
import * as GrIcons from 'react-icons/gr';
import * as RiIcons from 'react-icons/ri';

import {
  CREATE_TEMPLATE_LINK,
  LOGIN_LINK,
  MY_TEMPLATES_LINK,
  PROFILE_LINK,
  REGISTER_LINK,
} from './router';

export const EXPIRATION_TIME = 43200000;
export const SHOW_ALERT_TIME = 3000;
export const DRAWER_WIDTH = 240;
export const LOADER_DELAY = 3000;
export const TIMER_DELAY = 2000;
export const ID = 'identificator';
export const LTR = 'ltr';
export const TEMPLATE_FOREGROUND_COLOR = '#000000';
export const TEMPLATE_BACKGROUND_COLOR = '#ffffff';
export const LOADER_COLOR = '#00BFFF';
export const LOADER_TYPE = 'Puff';
export const PRODUCTION = 'production';
export const THOUSAND = 1000;
export const TOKEN = 'token';
export const BEARER = 'Bearer';
export const LOGO_MAX_FILE_SIZE = 52428800;

const MY_TEMPLATES = 'Шаблоны';
const CREATE_TEMPLATE = 'Добавить шаблон';
const PROFILE = 'Профиль';
const LOGIN = 'Авторизация';
const REGISTER = 'Регистрация';

export const UN_AUTH = 'Unauthorized';
export const ISE = 'Internal Server Error';
export const NOT_FOUND = 'Not Found';
export const WRONG_EMAIL_PASS = 'Неправильный email или пароль';
export const LOGIN_SUCCESS_MESSAGE = 'Успешный логин';
export const REGISTER_SUCCESS_MESSAGE = 'Компания успешно зарегистрирована, Вы вошли';
export const LOGOUT_SUCCESS_MESSAGE = 'Вы вышли из учетной записи';
export const REPETITIVE_COMPANY = 'Такая компания уже существует';
export const CREATE_TEMPLATE_SUCCESS_MESSAGE = 'Шаблон успешно создан';
export const CREATE_TEMPLATE_ERROR_MESSAGE = 'Ошибка создания шаблона';

export const LINKS_FOR_AUTH_USERS = [
  {
    to: MY_TEMPLATES_LINK,
    label: MY_TEMPLATES,
    exact: false,
    icon: <BsIcons.BsCollection />,
    id: 1,
  },
  {
    to: CREATE_TEMPLATE_LINK,
    label: CREATE_TEMPLATE,
    exact: false,
    icon: <GrIcons.GrTemplate />,
    id: 2,
  },
  { to: PROFILE_LINK, label: PROFILE, exact: false, icon: <CgIcons.CgProfile />, id: 3 },
];

export const LINKS_FOR_GUESTS = [
  { to: LOGIN_LINK, label: LOGIN, exact: false, icon: <AiIcons.AiOutlineLogin />, id: 5 },
  { to: REGISTER_LINK, label: REGISTER, exact: false, icon: <RiIcons.RiRegisteredLine />, id: 6 },
];
