import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@material-ui/core';
import parsePhoneNumberFromString from 'libphonenumber-js';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as RiIcons from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { FormWrapper } from '../../../components/FormWrapper';
import { Input } from '../../../components/Input';
import { MainContainer } from '../../../components/MainContainer';
import SubmitFormButton from '../../../components/UI/Buttons/SubmitFormButton';
import { LOGIN_LINK, REGISTER_SUCCESS_MESSAGE, REPETITIVE_COMPANY } from '../../../constants';
import { isFulfilled, isRejected } from '../../../helpers/handleRequest';
import { showErrorMessage, showSuccessMessage } from '../../../helpers/messages';
import { registrateClient } from '../../../redux/authSlice';

import { schema } from './companyRegShema';

const normalizePhoneNumber = (value) => {
  const phoneNumber = parsePhoneNumberFromString(value);
  if (!phoneNumber) {
    return value;
  }
  return phoneNumber.formatInternational();
};

export const RegistrationHookForm = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirm: '',
      organizationName: '',
      url: '',
      phoneNumber: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const registrate = async (values) => {
    const response = await dispatch(registrateClient(values));
    const requestStatus = response.meta.requestStatus;

    if (isFulfilled(requestStatus)) {
      showSuccessMessage(REGISTER_SUCCESS_MESSAGE);
      return;
    }
    if (isRejected(requestStatus)) {
      showErrorMessage(REPETITIVE_COMPANY);
      return;
    }
  };

  const onSubmit = (data) => {
    let organizedValues = {
      user: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
      },
      company: {
        organizationName: data.organizationName,
        url: data.url,
        phoneNumber: data.phoneNumber,
      },
    };
    registrate(organizedValues);
  };

  return (
    <MainContainer>
      <Typography component="h2" variant="h5" align="center">
        <RiIcons.RiRegisteredLine />
        <span>Регистрация</span>
      </Typography>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('organizationName')}
          id="organizationName"
          type="text"
          label="Название компании"
          error={!!errors.organizationName}
          helperText={errors?.organizationName?.message}
        />
        <Input
          {...register('url')}
          id="url"
          type="text"
          label="Сайт компании"
          error={!!errors.url}
          helperText={errors?.url?.message}
        />
        <Input
          {...register('firstName')}
          id="firstName"
          type="text"
          label="Имя"
          error={!!errors.firstName}
          helperText={errors?.firstName?.message}
        />
        <Input
          {...register('lastName')}
          id="lastName"
          type="text"
          label="Фамилия"
          error={!!errors.lastName}
          helperText={errors?.lastName?.message}
        />
        <Input
          {...register('email')}
          id="email"
          type="email"
          label="E-mail"
          error={!!errors.email}
          helperText={errors?.email?.message}
        />
        <Input
          {...register('password')}
          id="password"
          type="password"
          label="Пароль"
          error={!!errors.password}
          helperText={errors?.password?.message}
        />
        <Input
          {...register('passwordConfirm')}
          id="passwordConfirm"
          type="password"
          label="Подтверждение пароля"
          error={!!errors.passwordConfirm}
          helperText={errors?.passwordConfirm?.message}
        />
        <Input
          {...register('phoneNumber')}
          id="phoneNumber"
          type="tel"
          label="Телефон"
          onChange={(event) => {
            event.target.value = normalizePhoneNumber(event.target.value);
          }}
          error={!!errors.phoneNumber}
          helperText={errors?.phoneNumber?.message}
        />
        <SubmitFormButton text="Зарегистрироваться" />
      </FormWrapper>
      <Box>
        <div>
          <span>Уже зарегистрированы? </span>
          <NavLink to={LOGIN_LINK}>
            <span>Авторизуйтесь!</span>
          </NavLink>
        </div>
      </Box>
    </MainContainer>
  );
};
