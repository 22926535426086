import * as yup from 'yup';

import { LETTERS_REGEXP, PHONE_NUMBER_REGEXP } from '../../../constants';

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .max(15, 'Must be 15 characters or less')
    .required('Required')
    .matches(LETTERS_REGEXP, 'Can only contain Latin and Russian letters'),
  lastName: yup
    .string()
    .max(15, 'Must be 15 characters or less')
    .required('Required')
    .matches(LETTERS_REGEXP, 'Can only contain Latin and Russian letters'),
  //TODO - fix this
  //.url('Invalid URL'),
  identifier: yup.string().required('Required'),
  comment: yup.string().required('Required'),
  phoneNumber: yup
    .string()
    .required('No phone number provided')
    .matches(PHONE_NUMBER_REGEXP, 'Wrong format'),
});
