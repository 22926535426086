import React from 'react';

import { AppRouter } from './components/AppRouter/AppRouter';
import { Layout } from './components/hoc/Layout/Layout';

function App() {
  return (
    <Layout>
      <AppRouter />
    </Layout>
  );
}

export default App;
