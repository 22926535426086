import React from 'react';
import { useSelector } from 'react-redux';

import { MainContainer } from '../../../components/MainContainer';
import { LoaderComponent } from '../../../components/UI/LoaderComponent/LoaderComponent';

import { UpdateCompanyHookForm } from './UpdateCompanyHookForm';

export const UpdateCompany = () => {
  const loading = useSelector((state) => state.users.loading);
  return <MainContainer>{loading ? <LoaderComponent /> : <UpdateCompanyHookForm />}</MainContainer>;
};
