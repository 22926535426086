import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { handleFetches } from '../API/fetch';
import {
  FETCH_COMPANY_POSTFIX,
  LOGIN_POSTFIX,
  LOGOUT_POSTFIX,
  REGISTRATION_POSTFIX,
} from '../constants';
import { BASE_URL } from '../constants';
import { handleRequestErrors } from '../helpers/handleErrors';

export const loginClient = createAsyncThunk('auth/login', (body, { rejectWithValue }) => {
  return handleFetches(
    `${BASE_URL}${LOGIN_POSTFIX}`,
    { method: 'POST', body: body },
    false,
    false,
    {
      handlerError: handleRequestErrors,
      showAlert: false,
    },
    rejectWithValue
  );
});

export const registrateClient = createAsyncThunk('auth/registrate', (body, { rejectWithValue }) => {
  return handleFetches(
    `${BASE_URL}${REGISTRATION_POSTFIX}`,
    { method: 'POST', body: body },
    false,
    false,
    { handlerError: handleRequestErrors, showAlert: false },
    rejectWithValue
  );
});

export const logoutClient = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  return handleFetches(
    `${BASE_URL}${LOGOUT_POSTFIX}`,
    { method: 'POST', body: {} },
    true,
    true,
    {
      handlerError: handleRequestErrors,
    },
    rejectWithValue
  );
});

export const checkAuthStatus = createAsyncThunk(
  'auth/check-auth',
  async (_, { rejectWithValue }) => {
    return handleFetches(
      `${BASE_URL}${FETCH_COMPANY_POSTFIX}`,
      { method: 'GET', body: {} },
      true,
      false,
      {
        handlerError: handleRequestErrors,
      },
      rejectWithValue
    );
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    authStatus: false,
  },
  reducers: {
    authSuccess(state) {
      state.authStatus = true;
    },
    authFail(state) {
      state.authStatus = false;
    },
  },
  extraReducers: {
    [registrateClient.fulfilled]: (state) => {
      state.loading = false;
      state.authStatus = true;
    },
    [registrateClient.pending]: (state) => {
      state.loading = true;
    },
    [registrateClient.rejected]: (state) => {
      state.loading = false;
      state.authStatus = false;
    },
    [loginClient.fulfilled]: (state) => {
      state.loading = false;
      state.authStatus = true;
    },
    [loginClient.pending]: (state) => {
      state.loading = true;
    },
    [loginClient.rejected]: (state) => {
      state.loading = false;
      state.authStatus = false;
    },
    [logoutClient.fulfilled]: (state) => {
      state.loading = false;
      state.authStatus = false;
    },
    [logoutClient.pending]: (state) => {
      state.loading = true;
    },
    [logoutClient.rejected]: (state) => {
      state.loading = false;
    },
    [checkAuthStatus.fulfilled]: (state) => {
      state.loading = false;
      state.authStatus = true;
    },
    [checkAuthStatus.pending]: (state) => {
      state.loading = true;
    },
    [checkAuthStatus.rejected]: (state) => {
      state.loading = false;
      state.authStatus = false;
    },
  },
});

export const { authSuccess, authFail } = authSlice.actions;
export default authSlice.reducer;
