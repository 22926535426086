import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import {
  CREATE_TEMPLATE_LINK,
  MY_TEMPLATES_LINK,
  PROFILE_LINK,
  TEMPLATE_STEP_RESULT,
  TEMPLATE_STEP_THREE,
  TEMPLATE_STEP_TWO,
} from '../../../constants';
import { Profile } from '../../Profile';
import { MyTemplates } from '../MyTemplates';

import { DataProvider } from './DataContext';
import { Result } from './Result';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';

export const CreateTemplate = () => {
  return (
    <DataProvider>
      <>
        <BrowserRouter>
          <Switch>
            <Route path={CREATE_TEMPLATE_LINK} component={Step1} />
            <Route path={TEMPLATE_STEP_TWO} component={Step2} />
            <Route path={TEMPLATE_STEP_THREE} component={Step3} />
            <Route path={TEMPLATE_STEP_RESULT} component={Result} />
            <Route path={MY_TEMPLATES_LINK} component={MyTemplates} />
            <Route path={PROFILE_LINK} component={Profile} />
          </Switch>
        </BrowserRouter>
      </>
    </DataProvider>
  );
};
