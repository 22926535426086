import { Box, Button, Container, List, ListItem } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EditFlowingButton from '../../components/UI/Buttons/EditFlowingButton';
import SettingsFlowingButton from '../../components/UI/Buttons/SettingsFlowingButton';
import { LoaderComponent } from '../../components/UI/LoaderComponent';
import { CHANGE_PASSWORD_LINK, DEFAULT_LINK, UPDATE_COMPANY_LINK } from '../../constants';
import { logoutClient } from '../../redux/authSlice';
import { deleteCompany, fetchCompanyData, fetchUserData } from '../../redux/usersSlice';

import cls from './Profile.module.css';

export const Profile = () => {
  const url = useSelector((state) => state.users.companyData.url);
  const organizationName = useSelector((state) => state.users.companyData.organizationName);
  const phoneNumber = useSelector((state) => state.users.companyData.phoneNumber);
  const createdAt = useSelector((state) => state.users.companyData.createdAt);
  const updatedAt = useSelector((state) => state.users.companyData.updatedAt);
  const email = useSelector((state) => state.users.userData.email);
  const firstName = useSelector((state) => state.users.userData.firstName);
  const lastName = useSelector((state) => state.users.userData.lastName);
  const role = useSelector((state) => state.users.userData.role);
  const loading = useSelector((state) => state.users.loading);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchCompanyData());
    dispatch(fetchUserData());
  }, [dispatch]);

  const memoizedEditPasswordHandler = useCallback(() => {
    history.push(CHANGE_PASSWORD_LINK);
  }, [history]);

  const memoizedSettingsClickHandler = useCallback(() => {
    history.push(UPDATE_COMPANY_LINK);
  }, [history]);

  const handleLogout = useCallback(async () => {
    const response = await dispatch(logoutClient());
    if (response.meta.requestStatus === 'fulfilled') {
      history.push(DEFAULT_LINK);
    }
  }, [history, dispatch]);

  const handleDelete = useCallback(() => {
    dispatch(deleteCompany());
    dispatch(logoutClient());
  }, [dispatch]);

  const handleDate = useCallback((date) => {
    return moment(date).format('LLLL');
  }, []);

  return (
    <Container className={cls.ProfileContainer}>
      {loading ? (
        <LoaderComponent />
      ) : (
        <>
          <Box className={cls.ProfileHeader}>
            <h1>Личный кабинет</h1>
            <SettingsFlowingButton onClick={memoizedSettingsClickHandler} />
          </Box>
          <hr />
          <h5>ВАШИ ДАННЫЕ</h5>
          <List>
            <Box>
              <ListItem>
                <div>Ваш email</div>
                <div>{email}</div>
              </ListItem>
              <hr />
            </Box>
            <Box>
              <ListItem>
                <div>Имя пользователя</div>
                <div>{firstName}</div>
              </ListItem>
              <hr />
            </Box>
            <Box>
              <ListItem>
                <div>Фамилия пользователя</div>
                <div>{lastName}</div>
              </ListItem>
              <hr />
            </Box>
            <Box>
              <ListItem>
                <div>Роль пользователя</div>
                <div>{role}</div>
              </ListItem>
              <hr />
            </Box>
            <Box>
              <ListItem>
                <div>Название компании</div>
                <div>{organizationName}</div>
              </ListItem>
              <hr />
            </Box>
            <Box>
              <ListItem>
                <div>Сайт компании</div>
                <div>{url}</div>
              </ListItem>
              <hr />
            </Box>
            <Box>
              <ListItem>
                <div>Телефон компании</div>
                <div>{phoneNumber}</div>
              </ListItem>
              <hr />
            </Box>
            <Box>
              <ListItem>
                <div>Дата создания аккаунта</div>
                <div>{handleDate(createdAt)}</div>
              </ListItem>
              <hr />
            </Box>
            <Box>
              <ListItem>
                <div>Дата последнего обновления информации</div>
                <div>{handleDate(updatedAt)}</div>
              </ListItem>
              <hr />
            </Box>
          </List>
          <Box>
            <div className={cls.PasswordContainer}>
              <span>Смена пароля</span>
              <EditFlowingButton onClick={memoizedEditPasswordHandler} />
            </div>
            <hr />
          </Box>
          <Box className={cls.DeleteProfileContainer}>
            <div>
              <span className={cls.DeleteSpan}>Вы можете удалить свой профиль</span>
              <Button variant="outlined" onClick={handleDelete} startIcon={<DeleteIcon />}>
                Удалить
              </Button>
            </div>
          </Box>
          <Box className={cls.BoxForButton}>
            <Button variant="outlined" onClick={handleLogout}>
              Выйти
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};
