import { IconButton, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
}));

export const MenuButton = React.memo(function MenuButton({ onClick }) {
  const classes = useStyles();
  const menuOpened = useSelector((state) => state.menu.menuOpened);

  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={onClick}
      edge="start"
      className={clsx(classes.menuButton, menuOpened && classes.hide)}
    >
      <MenuIcon />
    </IconButton>
  );
});

MenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
