import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

export const ColorInput = ({ name, errors, styles, control }) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FormControl fullWidth className={styles.root}>
            <InputLabel htmlFor={name}>Цвет текста</InputLabel>
            <OutlinedInput {...field} id={name} type="color" error={!!errors.name} />
            {errors?.name && <FormHelperText>{errors?.name.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );
};

ColorInput.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  styles: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
};

ColorInput.defaultProps = {
  errors: {},
};
