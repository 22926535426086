import React from 'react';
import { useSelector } from 'react-redux';

import { MainContainer } from '../../../components/MainContainer';
import { LoaderComponent } from '../../../components/UI/LoaderComponent';

import { RegistrationHookForm } from './RegistrationHookForm';

export const CompanyRegistration = () => {
  const loading = useSelector((state) => state.auth.loading);

  return <MainContainer>{loading ? <LoaderComponent /> : <RegistrationHookForm />}</MainContainer>;
};
