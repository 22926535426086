import { yupResolver } from '@hookform/resolvers/yup';
import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ColorInput } from '../../../components/ColorInput';
import { FileInput } from '../../../components/FileInput';
import { FormWrapper } from '../../../components/FormWrapper';
import { Input } from '../../../components/Input';
import { MainContainer } from '../../../components/MainContainer';
import SubmitFormButton from '../../../components/UI/Buttons/SubmitFormButton';
import { MY_TEMPLATES_LINK } from '../../../constants';
import { showErrorMessage, showSuccessMessage } from '../../../helpers/messages';
import { getCompanyTemplates, updateCompanyTemplate } from '../../../redux/templatesSlice';

import { schema } from './editTemplateShema';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const EditTemplateHookForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const arrayOfTemplates = useSelector((state) => state.templates.templates.rows);
  const { id } = useParams();
  const organizationName = useSelector((state) => state.users.companyData.organizationName);
  const organizationURL = useSelector((state) => state.users.companyData.url);
  const organizationPhoneNumber = useSelector((state) => state.users.companyData.phoneNumber);
  const styles = useStyles();

  const template = arrayOfTemplates.filter((item) => item.id === id)[0];

  useEffect(() => {
    dispatch(getCompanyTemplates());
  }, [dispatch]);

  const { foregroundColor, backgroundColor, templateName } = template;

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      templateName: templateName,
      organizationName: organizationName,
      url: organizationURL,
      phoneNumber: organizationPhoneNumber,
      backgroundColor: backgroundColor,
      foregroundColor: foregroundColor,
      latitude: '',
      longitude: '',
      Logo: '',
      Strip: '',
      Background: '',
      Icon: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const updateTemplate = async (values) => {
    let formdata = new FormData();

    formdata.append('templateName', values.templateName);
    formdata.append('foregroundColor', values.foregroundColor);

    formdata.append('backgroundColor', values.backgroundColor);
    formdata.append('Logo', values.Logo);
    formdata.append('Icon', values.Icon);
    formdata.append('Background', values.Background);
    formdata.append('Strip', values.Strip);

    const response = await dispatch(updateCompanyTemplate({ formdata, id }));

    const isResponseOk = response?.payload.every((item) => item === 'Ok');

    if (isResponseOk) {
      showSuccessMessage('Template updated');
      history.push(MY_TEMPLATES_LINK);
    } else if (response.payload.code === 400) {
      showErrorMessage(response.payload.message);
      return;
    } else {
      showErrorMessage(response.payload.message);
      return;
    }
  };

  const onSubmit = (data) => {
    updateTemplate(data);
  };

  const watchLogo = watch('Logo');
  const watchIcon = watch('Icon');
  const watchBackground = watch('Background');
  const watchStrip = watch('Strip');

  return (
    <MainContainer>
      <Typography component="h2" variant="h5" align="center">
        <span>Редактирование шаблона</span>
      </Typography>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('templateName')}
          id="templateName"
          type="text"
          label="Название шаблона"
          error={!!errors.templateName}
          helperText={errors?.templateName?.message}
        />
        <Input
          {...register('organizationName')}
          id="organizationName"
          type="text"
          label="Название компании"
          error={!!errors.organizationName}
          helperText={errors?.organizationName?.message}
        />
        <Input
          {...register('url')}
          id="url"
          type="text"
          label="Сайт компании"
          error={!!errors.url}
          helperText={errors?.url?.message}
        />
        <Input
          {...register('phoneNumber')}
          id="phoneNumber"
          type="text"
          label="Телефон"
          error={!!errors.phoneNumber}
          helperText={errors?.phoneNumber?.message}
        />
        <Input
          {...register('foregroundColor')}
          id="foregroundColor"
          type="text"
          label="Цвет текста"
          error={!!errors.foregroundColor}
          helperText={errors?.foregroundColor?.message}
        />
        <Input
          {...register('backgroundColor')}
          id="backgroundColor"
          type="text"
          label="Цвет фона"
          error={!!errors.backgroundColor}
          helperText={errors?.backgroundColor?.message}
        />
        <ColorInput control={control} name="foregroundColor" styles={styles} />
        <ColorInput control={control} name="backgroundColor" styles={styles} />
        <Input
          {...register('latitude')}
          id="latitude"
          type="text"
          label="Широта"
          error={!!errors.latitude}
          helperText={errors?.latitude?.message}
        />
        <Input
          {...register('longitude')}
          id="longitude"
          type="text"
          label="Долгота"
          error={!!errors.longitude}
          helperText={errors?.longitude?.message}
        />
        <FileInput setValue={setValue} name="Logo" id="Logo" />
        {watchLogo && <p>{getValues('Logo').name}</p>}
        <FileInput setValue={setValue} name="Icon" id="Icon" />
        {watchIcon && <p>{getValues('Icon').name}</p>}
        <FileInput setValue={setValue} name="Background" id="Background" />
        {watchBackground && <p>{getValues('Background').name}</p>}
        <FileInput setValue={setValue} id="strip " name="Strip" />
        {watchStrip && <p>{getValues('Strip').name}</p>}
        <Box marginTop={5}>
          <SubmitFormButton text="Обновить" />
        </Box>
      </FormWrapper>
    </MainContainer>
  );
};
