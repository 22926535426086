import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { PRODUCTION } from '../constants';

import authSlice from './authSlice';
import menuSlice from './menuSlice';
import templatesSlice from './templatesSlice';
import usersSlice from './usersSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    users: usersSlice,
    menu: menuSlice,
    templates: templatesSlice,
  },
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== PRODUCTION,
});

export default store;
