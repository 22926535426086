import { Container, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const MainContainer = ({ children, ...props }) => {
  const styles = useStyles();
  return (
    <Container component="main" maxWidth="xs" className={styles.root} {...props}>
      {children}
    </Container>
  );
};
