import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { FormWrapper } from '../../components/FormWrapper';
import { Input } from '../../components/Input';
import { MainContainer } from '../../components/MainContainer';
import SubmitFormButton from '../../components/UI/Buttons/SubmitFormButton';
import { DEFAULT_LINK } from '../../constants';
import { showSuccessMessage } from '../../helpers/messages';
import { changeCompanyPassword } from '../../redux/usersSlice';

import { schema } from './changePasswordShema';

export const ChangePasswordHookForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordCheck: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const redirectToProfile = () => {
    history.push(DEFAULT_LINK);
  };

  const changePasswordHandler = async (body) => {
    const data = await dispatch(changeCompanyPassword(body));

    if (data.meta.requestStatus === 'rejected') {
      showSuccessMessage('Password successfully changed');
      redirectToProfile();
    } else if (data.meta.requestStatus === 'fulfilled') {
      console.log('error');
      return;
    }
  };

  const onSubmit = (data) => {
    let body = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    changePasswordHandler(body);
  };

  return (
    <MainContainer>
      <Typography component="h2" variant="h5">
        Сменить пароль
      </Typography>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('oldPassword')}
          id="oldPassword"
          type="password"
          label="Текущий пароль"
          error={!!errors.oldPassword}
          helperText={errors?.oldPassword?.message}
        />
        <Input
          {...register('newPassword')}
          id="newPassword"
          type="password"
          label="Новый пароль"
          error={!!errors.newPassword}
          helperText={errors?.newPassword?.message}
        />
        <Input
          {...register('newPasswordCheck')}
          id="newPasswordCheck"
          type="password"
          label="Подтверждение пароля"
          error={!!errors.newPasswordCheck}
          helperText={errors?.newPasswordCheck?.message}
        />
        <SubmitFormButton text="Сменить" />
      </FormWrapper>
    </MainContainer>
  );
};
