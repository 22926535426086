import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
  },
}));

export const FormWrapper = ({ children, ...props }) => {
  const styles = useStyles();
  return (
    <form {...props} noValidate className={styles.root}>
      {children}
    </form>
  );
};

FormWrapper.propTypes = {
  children: PropTypes.array,
  props: PropTypes.object,
};

FormWrapper.defaultProps = {
  children: <div>Что-то пошло не так</div>,
};
