import { Box } from '@material-ui/core';
import React from 'react';
import Loader from 'react-loader-spinner';

import { LOADER_COLOR, LOADER_DELAY, LOADER_TYPE } from '../../../constants';

import cls from './LoaderComponent.module.css';

export const LoaderComponent = () => {
  //other logic
  return (
    <Box className={cls.BoxForButton}>
      <Loader
        type={LOADER_TYPE}
        color={LOADER_COLOR}
        height={100}
        width={100}
        timeout={LOADER_DELAY} //3 secs
      />
    </Box>
  );
};
