import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';

import { FormWrapper } from '../../../components/FormWrapper';
import { Input } from '../../../components/Input';
import { MainContainer } from '../../../components/MainContainer';
import SubmitFormButton from '../../../components/UI/Buttons/SubmitFormButton';
import { TEMPLATE_STEP_TWO } from '../../../constants/router';

import { useData } from './DataContext';

const schema = yup.object().shape({
  name: yup.string().required('Required'),
});

export const Step1 = () => {
  const history = useHistory();
  const { data, setValues } = useData();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { name: data.name },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setValues(data);
    history.push(TEMPLATE_STEP_TWO);
  };

  return (
    <MainContainer>
      <Typography component="h2" variant="h5">
        🦄 Step 1 of template creation
      </Typography>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('name')}
          id="name"
          type="text"
          label="Template name"
          error={!!errors.name}
          helperText={errors?.name?.message}
        />
        <SubmitFormButton text="Next step" />
      </FormWrapper>
    </MainContainer>
  );
};
