import axios from 'axios';

import { BEARER, LOGOUT_SUCCESS_MESSAGE, TOKEN } from '../constants';
import { showSuccessMessage } from '../helpers/messages';

export const handleFetches = async (
  url,
  options,
  withCreds,
  isLogout,
  { handlerError = null, showAlert = true },
  rejectWithValue
) => {
  const method = options?.method || 'GET';
  let currentHeaders = options?.headers;

  if (withCreds) {
    let token = `${BEARER} ${localStorage.getItem(TOKEN)}`;
    currentHeaders = options?.headers || { Authorization: token };
  }

  try {
    const response = await axios({
      method: method,
      url: url,
      data: options?.body,
      headers: currentHeaders,
    });

    let data = response.data;
    let haveTokenInResponseData = TOKEN in data;

    if (response.status === 200) {
      let successText = response.statusText;

      if (!withCreds && haveTokenInResponseData) {
        localStorage.setItem(TOKEN, data.token);
      } else if (isLogout && withCreds) {
        localStorage.removeItem(TOKEN);
        successText = LOGOUT_SUCCESS_MESSAGE;
        showAlert && showSuccessMessage(successText);
      }

      return data;
    }
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
};
