export const PASSWORD_REGEXP = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
export const ORGANIZATION_NAME_REGEXP = /^(?=\S)[а-яА-ЯёЁa-zA-Z0-9_\s-]*\S$/;
export const LETTERS_REGEXP = /^[а-яА-ЯёЁa-zA-Z0-9]+$/;
export const PHONE_NUMBER_REGEXP = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/;
export const DOMAIN_NAME_REGEXP = /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;
export const COLOR_REGEXP = /#[a-f0-9]{6}\b/gi;
export const LATITUDE_REGEXP =
  /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
export const LONGITUDE_REGEXP =
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
export const PROFILE_REGEXP = /([A-Z][a-z]+)/g;
export const PROFILE_REGEXP_MOD = ' $1 ';
