import { ISE, NOT_FOUND, UN_AUTH } from '../constants';

import { showErrorMessage } from './messages';

export const simpleHandlerError = (error) => {
  const detail = error?.detail || '';
  const status = error?.status || '';
  const statusText = error?.statusText || '';

  if (statusText) {
    showErrorMessage(statusText);
  } else {
    showErrorMessage(`${status} (${detail})`);
  }
};

export const handleRequestErrors = ({ data, status, statusText }) => {
  if (status === 404 && statusText === NOT_FOUND) {
    showErrorMessage(data.message);
  } else if (status === 401) {
    showErrorMessage(UN_AUTH);
  } else if (status === 500) {
    showErrorMessage(ISE);
  } else {
    console.log('else in error handler');
    showErrorMessage(data.message);
  }
};
