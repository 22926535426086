import { Divider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DRAWER_WIDTH, LINKS_FOR_AUTH_USERS, LINKS_FOR_GUESTS } from '../../../constants';
import { hideMenu } from '../../../redux/menuSlice';
import DrawerHeader from '../../Drawer/DrawerHeader';
import { NavBar } from '../../NavBar';

import Links from './Links';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
}));

const MemoizedNavbar = React.memo(NavBar);
const MemoizedLinks = React.memo(Links);
const MemoizedDrawerHeader = React.memo(DrawerHeader);
const MemoizedDivider = React.memo(Divider);

export const Layout = ({ children }) => {
  const classes = useStyles();
  const isAuthenticated = useSelector((state) => state.auth.authStatus);
  const menuOpened = useSelector((state) => state.menu.menuOpened);
  const dispatch = useDispatch();

  const links = isAuthenticated ? LINKS_FOR_AUTH_USERS : LINKS_FOR_GUESTS;

  const handleDrawerClose = useCallback(() => {
    dispatch(hideMenu());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MemoizedNavbar />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={menuOpened}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <MemoizedDrawerHeader />
        <MemoizedDivider />
        <MemoizedLinks links={links} handleDrawerClose={handleDrawerClose} />
      </Drawer>
      <main className={clsx(classes.content)} onClick={handleDrawerClose}>
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
};

Layout.defaultProps = {
  children: <div>Что-то пошло не так</div>,
};
