import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { handleFetches } from '../API/fetch';
import { CHANGE_PASSWORD_POSTFIX, FETCH_COMPANY_POSTFIX, FETCH_USER_POSTFIX } from '../constants';
import { handleRequestErrors } from '../helpers/handleErrors';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState = {
  companyData: {},
  userData: {},
  loading: false,
  passwordChangeStatus: null,
  errorMessage: '',
  successMessage: '',
};

export const fetchCompanyData = createAsyncThunk(
  'users/fetch-company-info',
  (body, { rejectWithValue }) => {
    return handleFetches(
      `${BASE_URL}${FETCH_COMPANY_POSTFIX}`,
      { method: 'GET', body: body },
      true,
      false,
      {
        handlerError: handleRequestErrors,
      },
      rejectWithValue
    );
  }
);

export const fetchUserData = createAsyncThunk(
  'users/fetch-user-info',
  (body, { rejectWithValue }) => {
    return handleFetches(
      `${BASE_URL}${FETCH_USER_POSTFIX}`,
      { method: 'GET', body: body },
      true,
      false,
      {
        handlerError: handleRequestErrors,
      },
      rejectWithValue
    );
  }
);

export const deleteCompany = createAsyncThunk(
  'users/delete-company',
  (body, { rejectWithValue }) => {
    return handleFetches(
      `${BASE_URL}${FETCH_COMPANY_POSTFIX}`,
      { method: 'DELETE', body: body },
      true,
      false,
      { handlerError: handleRequestErrors },
      rejectWithValue
    );
  }
);

export const updateCompanyData = createAsyncThunk(
  'users/update-company-data',
  (body, { rejectWithValue }) => {
    return handleFetches(
      `${BASE_URL}${FETCH_COMPANY_POSTFIX}`,
      { method: 'PUT', body: body },
      true,
      false,
      {
        handlerError: handleRequestErrors,
      },
      rejectWithValue
    );
  }
);

export const changeCompanyPassword = createAsyncThunk(
  'users/change-password',
  (body, { rejectWithValue }) => {
    return handleFetches(
      `${BASE_URL}${CHANGE_PASSWORD_POSTFIX}`,
      { method: 'PUT', body: body },
      true,
      false,
      { handlerError: handleRequestErrors },
      rejectWithValue
    );
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: {
    [fetchCompanyData.pending]: (state) => {
      state.loading = true;
    },
    [fetchCompanyData.fulfilled]: (state, action) => {
      state.loading = false;
      state.companyData = action.payload;
      state.successMessage = 'Company data succesfully fetched';
    },
    [fetchUserData.pending]: (state) => {
      state.loading = true;
    },
    [fetchCompanyData.rejected]: (state) => {
      state.loading = false;
      state.companyData = {};
    },
    [fetchUserData.fulfilled]: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.successMessage = 'User data succesfully fetched';
    },
    [deleteCompany.pending]: (state) => {
      state.loading = true;
    },
    [fetchUserData.rejected]: (state) => {
      state.loading = false;
      state.userData = {};
    },
    [deleteCompany.fulfilled]: (state) => {
      state.loading = false;
      state.companyData = {};
      state.successMessage = 'Company succesfully deleted';
    },
    [updateCompanyData.pending]: (state) => {
      state.loading = true;
    },
    [updateCompanyData.fulfilled]: (state, action) => {
      state.loading = false;
      state.companyData = action.payload;
      state.successMessage = 'Company data succesfully updated';
    },
    [changeCompanyPassword.pending]: (state) => {
      state.loading = true;
    },
    [changeCompanyPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.passwordChangeStatus = action.payload;
      state.successMessage = 'Company password succesfully updated';
    },
  },
});

export default usersSlice.reducer;
