import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import parsePhoneNumberFromString from 'libphonenumber-js';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { FormWrapper } from '../../../components/FormWrapper';
import { Input } from '../../../components/Input';
import { MainContainer } from '../../../components/MainContainer';
import SubmitFormButton from '../../../components/UI/Buttons/SubmitFormButton';
import { CARD_CREATED_LINK, DEFAULT_LINK } from '../../../constants';

import { schema } from './createCardShema';

const normalizePhoneNumber = (value) => {
  const phoneNumber = parsePhoneNumberFromString(value);
  if (!phoneNumber) {
    return value;
  }
  return phoneNumber.formatInternational();
};

export const CreateCardHookForm = () => {
  //const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: 'sd',
      lastName: 'sd',
      identifier: 'sd',
      comment: 'asd',
      phoneNumber: '+79961772122',
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { id } = useParams();

  const onSubmit = () => {
    history.push(`${CARD_CREATED_LINK}${DEFAULT_LINK}${id}`);
  };

  return (
    <MainContainer>
      <Typography component="h2" variant="h5" align="center">
        <span>Создание карты</span>
      </Typography>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('firstName')}
          id="firstName"
          type="text"
          label="Имя"
          error={!!errors.firstName}
          helperText={errors?.firstName?.message}
        />
        <Input
          {...register('lastName')}
          id="lastName"
          type="text"
          label="Фамилия"
          error={!!errors.lastName}
          helperText={errors?.lastName?.message}
        />
        <Input
          {...register('phoneNumber')}
          id="phoneNumber"
          type="tel"
          label="Телефон"
          onChange={(event) => {
            event.target.value = normalizePhoneNumber(event.target.value);
          }}
          error={!!errors.phoneNumber}
          helperText={errors?.phoneNumber?.message}
        />
        <Input
          {...register('identifier')}
          id="identifier"
          type="text"
          label="Индентификатор"
          error={!!errors.identifier}
          helperText={errors?.identifier?.message}
        />
        <Input
          {...register('comment')}
          id="comment"
          type="text"
          label="Комментарий"
          error={!!errors.comment}
          helperText={errors?.comment?.message}
        />
        <SubmitFormButton text="Создать карту" />
      </FormWrapper>
    </MainContainer>
  );
};
