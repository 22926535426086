import mdtoast from '@dmuy/toast';

import { THOUSAND } from '../constants/constants';
import '@dmuy/toast/dist/mdtoast.css';

const alertOptions = { duration: THOUSAND };

export const showSuccessMessage = (message = 'Succes') => {
  mdtoast.success(message, alertOptions);
};

export const showErrorMessage = (message) => {
  mdtoast.error(message, alertOptions);
};
