import { Box, Button, List, ListItem, Container, Typography } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AddFlowingButton from '../../../components/UI/Buttons/AddFlowingButton';
import {
  CREATE_TEMPLATE_LINK,
  CREATE_CARD_LINK,
  EDIT_TEMPLATE_LINK,
  DEFAULT_LINK,
} from '../../../constants';
import { getCompanyTemplates, deleteTemplate } from '../../../redux/templatesSlice';

import cls from './MyTemplates.module.css';

export const MyTemplates = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.templates.templates);
  const totalTemplates = useSelector((state) => state.templates.totalTemplates);

  useEffect(() => {
    dispatch(getCompanyTemplates());
  }, [dispatch, totalTemplates]);

  const memoizedAddClickHandler = useCallback(() => {
    history.push(CREATE_TEMPLATE_LINK);
  }, [history]);

  const deleteClickHandler = (id) => {
    dispatch(deleteTemplate({ id }));
  };

  const memoizedEditTemplateClickHandler = useCallback(
    (id) => {
      history.push(`${EDIT_TEMPLATE_LINK}${DEFAULT_LINK}${id}`);
    },
    [history]
  );

  const memoizedCreateCardClickHandler = useCallback(
    (id) => {
      history.push(`${CREATE_CARD_LINK}${DEFAULT_LINK}${id}`);
    },
    [history]
  );

  /*
  const memoizedViewCardsClickHandler = useCallback(() => {
    history.push(CREATE_TEMPLATE_LINK);
  }, [history]);*/

  const arrayOfTemplates = templates.rows;

  return (
    <Container className={cls.MyTemplatesContainer}>
      <h1>Шаблоны карт</h1>
      <hr />
      {totalTemplates > 0 ? (
        <List>
          <Box>
            {arrayOfTemplates.map((item) => (
              <div key={item.id}>
                <ListItem className={cls.MyTemplatesContent}>
                  <h5>{item.templateName}</h5>
                  <div>
                    <Button
                      variant="outlined"
                      onClick={() => memoizedEditTemplateClickHandler(item.id)}
                    >
                      править
                    </Button>
                    <Button variant="outlined" onClick={() => deleteClickHandler(item.id)}>
                      удалить
                    </Button>
                    <Button variant="outlined">карты</Button>
                    <Button
                      variant="outlined"
                      onClick={() => memoizedCreateCardClickHandler(item.id)}
                    >
                      создать карту
                    </Button>
                  </div>
                </ListItem>
                {!(arrayOfTemplates.length - 1 === arrayOfTemplates.indexOf(item)) && <hr />}
              </div>
            ))}
          </Box>
        </List>
      ) : (
        <Box className={cls.MyTemplatesContent}>
          <Typography>Не было создано ни одного шаблона</Typography>
          <AddFlowingButton onClick={memoizedAddClickHandler} />
        </Box>
      )}
    </Container>
  );
};
