import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DRAWER_WIDTH } from '../../constants';
import { showMenu } from '../../redux/menuSlice';
import { fetchCompanyData } from '../../redux/usersSlice';
import { MenuButton } from '../UI/Buttons/MenuButton';

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export const NavBar = () => {
  const classes = useStyles();
  const menuOpened = useSelector((state) => state.menu.menuOpened);
  const isAuthenticated = useSelector((state) => state.auth.authStatus);
  const dispatch = useDispatch();
  const organizationName = useSelector((state) => state.users.companyData.organizationName);

  useEffect(() => {
    if (!organizationName) {
      dispatch(fetchCompanyData());
    }
  }, [dispatch, organizationName]);

  const handleDrawer = useCallback(() => {
    dispatch(showMenu());
  }, [dispatch]);

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: menuOpened,
        })}
      >
        <Toolbar>
          <MenuButton onClick={handleDrawer} />
          {isAuthenticated ? (
            <Typography variant="h6" noWrap>
              Вы авторизованы как {organizationName}
            </Typography>
          ) : (
            <Typography variant="h6" noWrap>
              Вы не авторизованы
            </Typography>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
