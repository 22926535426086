import * as yup from 'yup';

import {
  LETTERS_REGEXP,
  ORGANIZATION_NAME_REGEXP,
  PASSWORD_REGEXP,
  PHONE_NUMBER_REGEXP,
} from '../../../constants';

export const schema = yup.object().shape({
  email: yup.string().email('Invalid email address').required('No e-mail provided'),
  firstName: yup
    .string()
    .max(15, 'Must be 15 characters or less')
    .required('Required')
    .matches(LETTERS_REGEXP, 'Can only contain Latin and Russian letters'),
  lastName: yup
    .string()
    .max(15, 'Must be 15 characters or less')
    .required('Required')
    .matches(LETTERS_REGEXP, 'Can only contain Latin and Russian letters'),
  password: yup
    .string()
    .required('No password provided')
    .matches(PASSWORD_REGEXP, 'Password can only contain Latin letters.'),
  passwordConfirm: yup
    .string()
    .required('No password provided')
    .matches(PASSWORD_REGEXP, 'Password can only contain Latin letters.')
    .oneOf([yup.ref('password')], 'Password missmatch'),
  organizationName: yup
    .string()
    .required('No organization name provided')
    .matches(
      ORGANIZATION_NAME_REGEXP,
      'With a limitation of 2-20 characters, which can be ' +
        'letters and numbers, the first character must be a letter'
    ),
  url: yup.string().required('No url provided'),
  //TODO - fix this
  //.url('Invalid URL'),
  phoneNumber: yup
    .string()
    .required('No phone number provided')
    .matches(PHONE_NUMBER_REGEXP, 'Wrong format'),
});
