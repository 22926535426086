import * as yup from 'yup';

import { PHONE_NUMBER_REGEXP } from '../../../constants';

export const schema = yup.object().shape({
  templateName: yup.string().required('Required'),
  organizationName: yup.string().required('Required'),
  url: yup.string().required('No url provided'),
  //TODO - fix this
  //.url('Invalid URL'),
  phoneNumber: yup
    .string()
    .required('No phone number provided')
    .matches(PHONE_NUMBER_REGEXP, 'Wrong format'),
  backgroundColor: yup.string().required('No background color provided'),
  foregroundColor: yup.string().required('No background color provided'),
  longitude: yup.string(),
  latitude: yup.string(),
});
