import * as yup from 'yup';

import { PASSWORD_REGEXP } from '../../constants';

export const schema = yup.object().shape({
  email: yup.string().email('Invalid email address').required('No e-mail provided'),
  password: yup
    .string()
    .required('No password provided')
    .matches(
      PASSWORD_REGEXP,
      'Строчные и прописные латинские буквы, цифры, спецсимволы. Минимум 8 символов'
    ),
});
