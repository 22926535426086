import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { LINKS_FOR_GUESTS } from '../../../constants';
import cls from '../../Drawer/Drawer.module.css';

function Links({ links, handleDrawerClose }) {
  return (
    <List>
      {links.map((link) => {
        return (
          <ListItem key={link.id}>
            <NavLink to={link.to} exact={link.exact} style={cls} onClick={handleDrawerClose}>
              <ListItemIcon>{link.icon}</ListItemIcon>
              <ListItemText>{link.label}</ListItemText>
            </NavLink>
          </ListItem>
        );
      })}
    </List>
  );
}

Links.propTypes = {
  links: PropTypes.array,
  handleDrawerClose: PropTypes.func.isRequired,
};
Links.defaultProps = {
  links: LINKS_FOR_GUESTS,
};

export default Links;
