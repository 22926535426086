import React from 'react';
import { useSelector } from 'react-redux';

import { MainContainer } from '../../components/MainContainer';
import { LoaderComponent } from '../../components/UI/LoaderComponent';

import { LoginHookForm } from './LoginHookForm';

export const Login = () => {
  const loading = useSelector((state) => state.auth.loading);

  return <MainContainer>{loading ? <LoaderComponent /> : <LoginHookForm />}</MainContainer>;
};
