import { IconButton, makeStyles, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { LTR } from '../../constants';
import { hideMenu } from '../../redux/menuSlice';

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

function DrawerHeader() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const memoizedHandleDrawerClose = useCallback(() => {
    dispatch(hideMenu());
  }, [dispatch]);

  return (
    <div className={classes.drawerHeader}>
      <IconButton onClick={memoizedHandleDrawerClose}>
        {theme.direction === LTR ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </div>
  );
}

export default DrawerHeader;
