import { Fab } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';
import React from 'react';

const SettingsFlowingButton = React.memo(function SettingsFlowingButton({ onClick }) {
  return (
    <Fab color="primary" aria-label="add" onClick={onClick}>
      <SettingsIcon />
    </Fab>
  );
});

SettingsFlowingButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SettingsFlowingButton;
