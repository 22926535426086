import { makeStyles, Typography } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';

import { showErrorMessage } from '../../helpers/messages';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#eee',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#333',
    padding: '10px',
    marginTop: '20px',
  },
  icon: {
    marginTop: '16px',
    color: '#888888',
    fontSize: '42px',
  },
}));

export const FileInput = ({ setValue, name }) => {
  const styles = useStyles();

  const { isDragActive, getRootProps, getInputProps, isDragReject } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    minSize: 0,
    maxSize: 409600,
  });

  return (
    <>
      <Typography component="h2" variant="h5" align="center">
        {name}
      </Typography>
      <div
        {...getRootProps({
          className: styles.root,
        })}
      >
        <CloudUpload className={styles.icon} />
        <input
          {...getInputProps()}
          onChange={(event) => {
            const file = event.target.files[0];
            setValue(name, file, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }}
        />
        {!isDragActive && <p>Click here or drop a file to upload!</p>}
        {isDragActive && !isDragReject && <p>Drop it like it's hot!</p>}
        {isDragReject && showErrorMessage('File type not accepted, sorry!')}
      </div>
    </>
  );
};

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};
