//routes
export const LOGIN_LINK = '/login';
export const REGISTER_LINK = '/registration';
export const TEMPLATES_LINK = '/templates';
export const MY_TEMPLATES_LINK = '/mytemplates';
export const CREATE_TEMPLATE_LINK = '/create-template';
export const PROFILE_LINK = '/profile';
export const LOGOUT_LINK = '/logout';
export const DEFAULT_LINK = '/';
export const CHANGE_PASSWORD_LINK = '/change-password';
export const UPDATE_COMPANY_LINK = '/update-my-company';
export const DELETE_COMPANY_LINK = '/delete-my-company';
export const TEMPLATE_STEP_TWO = '/template_step2';
export const TEMPLATE_STEP_THREE = '/template_step3';
export const TEMPLATE_STEP_RESULT = '/template_result';
export const EDIT_TEMPLATE_LINK = '/edit-template';
export const CREATE_CARD_LINK = '/create-card';
export const CARD_CREATED_LINK = '/created-card';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL;

//side-effect links
export const PASS2U_LINK =
  'https://play.google.com/store/apps/details?id=com.passesalliance.wallet';
export const QR_LINK =
  'https://www.kaspersky.ru/content/ru-ru/images/repository/isc/2020/9910/a-guide-to-qr-codes-and-how-to-scan-qr-codes-2.png';
