import React from 'react';

import { MainContainer } from '../../../components/MainContainer';

import { EditTemplateHookForm } from './EditTemplateHookForm';

const EditTemplate = () => {
  return (
    <MainContainer>
      <EditTemplateHookForm />
    </MainContainer>
  );
};

export default EditTemplate;
