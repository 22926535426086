import React from 'react';
import { useSelector } from 'react-redux';

import { MainContainer } from '../../components/MainContainer';
import { LoaderComponent } from '../../components/UI/LoaderComponent/LoaderComponent';

import { ChangePasswordHookForm } from './ChangePasswordHookForm';

export const ChangePassword = () => {
  const loading = useSelector((state) => state.users.loading);
  return (
    <MainContainer>{loading ? <LoaderComponent /> : <ChangePasswordHookForm />}</MainContainer>
  );
};
