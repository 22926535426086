import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

export const Input = forwardRef((props, ref) => {
  return <TextField variant="outlined" margin="normal" ref={ref} fullWidth {...props} />;
});

Input.propTypes = {
  props: PropTypes.object,
};
