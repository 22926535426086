import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';

import { FileInput } from '../../../components/FileInput';
import { FormWrapper } from '../../../components/FormWrapper';
import { MainContainer } from '../../../components/MainContainer';
import SubmitFormButton from '../../../components/UI/Buttons/SubmitFormButton';
import { TEMPLATE_STEP_RESULT } from '../../../constants/router';

import { useData } from './DataContext';

const schema = yup.object().shape({
  Logo: yup.mixed().required('File required'),
  Icon: yup.mixed().required('File required'),
  Background: yup.mixed().required('File required'),
  Strip: yup.mixed().required('File required'),
});

export const Step3 = () => {
  const { setValues } = useData();
  const history = useHistory();

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    //formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setValues(data);
    history.push(TEMPLATE_STEP_RESULT);
  };

  const watchLogo = watch('Logo');
  const watchIcon = watch('Icon');
  const watchBackground = watch('Background');
  const watchStrip = watch('Strip');

  return (
    <MainContainer>
      <Typography component="h2" variant="h5">
        🦄 Step 3
      </Typography>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <FileInput setValue={setValue} name="Logo" />
        {watchLogo && <p>{getValues('Logo').name}</p>}
        <FileInput setValue={setValue} name="Icon" />
        {watchIcon && <p>{getValues('Icon').name}</p>}
        <FileInput setValue={setValue} name="Background" />
        {watchBackground && <p>{getValues('Background').name}</p>}
        <FileInput setValue={setValue} name="Strip" />
        {watchStrip && <p>{getValues('Strip').name}</p>}
        <SubmitFormButton text="Next step" />
      </FormWrapper>
    </MainContainer>
  );
};
